import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@insuma/mpp-ui/components/button';
import { Table } from '@insuma/mpp-ui/components/table';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { notification } from '@insuma/mpp-ui/notifications';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { customizationRoutePath, themesRoutePath } from 'core/constants/routes.constants';
import type { ICustomizationFilters, IDisplayCustomization } from 'core/model/interfaces/themes';
import { apiService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
import { EFilterThemeType, selectCustomization, updateFilters } from 'core/store/themes';
import { Search } from 'shared/components/search';
import { DeleteConfirmationModal } from '../components/delete-confirmation-modal';
import { getCustomizationColumns } from '../customization-utils';
import { useCustomizationList } from '../hooks/use-customization-list.hooks';

import './customization-list.scss';

export const CustomizationList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const css = useCSS('customization-list');
  const { filters } = useAppSelector(selectCustomization);
  const [query, setQuery] = useState<string>(filters.q);
  const [itemToDelete, setItemToDelete] = useState<IDisplayCustomization | null>(null);

  const { customizations, totalPages, isCustomizationLoading } = useCustomizationList(filters);

  const changeFilters = (filters: ICustomizationFilters) => {
    dispatch(
      updateFilters({
        type: EFilterThemeType.CUSTOMIZATION,
        filters,
      }),
    );
  };

  const onViewClick = (id: number) => navigate(`${themesRoutePath}${customizationRoutePath}/${id}/modulos`);
  const onEditClick = (id: number) => navigate(`${themesRoutePath}${customizationRoutePath}/editar/${id}`);
  const onDeleteConfirmation = async (id: number) => {
    try {
      await apiService.customization.deleteCustomization(id);
      changeFilters(filters);
      notification.success('La personalización ha sido eliminada');
    } catch (error) {
      notification.error('Error eliminando la personalización');
    }
  };

  const columns = getCustomizationColumns(onViewClick, onEditClick, item => setItemToDelete(item));

  const handleSearchEnterEvent: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    changeFilters({
      page: 1,
      q: query,
    });
  };

  const handleQueryClear = () => {
    setQuery('');
    changeFilters({
      page: 1,
      q: '',
    });
  };

  return (
    <div className={css()}>
      <h2 className={css('title')}>Personalización</h2>
      <div className={css('actions')}>
        <Search
          query={query}
          handleQueryClear={handleQueryClear}
          handleSearchChange={e => setQuery(e.target.value)}
          handleSearchEnterEvent={handleSearchEnterEvent}
          placeholder="Buscar por nombre, operador y lista de precios"
        />
      </div>
      <div className={css('actions')}>
        <span />
        <Button
          size="sm"
          type="button"
          iconName="plus"
          hasIcon="left"
          onClick={() => navigate(`${themesRoutePath}${customizationRoutePath}/crear`)}
        >
          Nueva personalización
        </Button>
      </div>
      <div>
        <DeleteConfirmationModal
          selectedItem={itemToDelete}
          setSelectedItem={setItemToDelete}
          onDeleteConfirmation={onDeleteConfirmation}
        />
        <Table
          isLoading={isCustomizationLoading}
          columns={columns}
          rows={customizations}
          paginationModel={{
            currentPage: filters.page - 1,
            onChangePage: page =>
              changeFilters({
                page,
                q: filters.q,
              }),
            totalPages,
            pageSize: itemsPerPage,
          }}
          noDataMessage="No se encontraron resultados"
        />
      </div>
    </div>
  );
};
