import React from 'react';
import { Text } from '@insuma/mpp-ui/components/typography';

interface IMinimumPurchaseItemFieldProps {
  label: string;
  value: string;
}

const MinimumPurchaseItemField = ({ label, value }: IMinimumPurchaseItemFieldProps) => (
  <div>
    <Text size="sm" weight="bold" isResponsive>
      {label}:
    </Text>
    <Text size="sm">{value}</Text>
  </div>
);

export default MinimumPurchaseItemField;
