export enum ESizeThemeModuleImage {
  DEFAULT = 'default',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}
export enum EThemeModuleType {
  BANNER = 1,
  SHORTCUT = 2,
  SUGGESTED_PRODUCT = 3,
  HIGHTLIGHTED_CAROUSEL = 4,
}

export enum EThemeType {
  DEFAULT = 1,
  HIGHTLIGHTED_CAROUSEL = 2,
}
