import { Link as RouterLink } from 'react-router-dom';
import { type TIconName } from '@insuma/mpp-ui/components/icon';
import { Link as Navigation } from '@insuma/mpp-ui/components/link';

import './link.scss';

export interface ILinkProps {
  children: React.ReactNode;
  to: string;
  icon?: TIconName;
}

export const Link = ({ to, icon, children }: ILinkProps) => (
  <RouterLink to={to}>
    <Navigation hasIcon={icon !== undefined} icon={{ name: icon }}>
      {children}
    </Navigation>
  </RouterLink>
);
