import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@insuma/mpp-ui/components/button';
import { useCSS } from '@insuma/mpp-ui/hooks';
import type { IStaticPagesFilters } from 'core/model/interfaces/static-pages';
import { useAppDispatch } from 'core/store';
import { updateFilters } from 'core/store/static-pages';
import { Search } from 'shared/components/search';

interface IHeaderActions {
  filters: IStaticPagesFilters;
  createRoute: string;
}
export const HeaderActions = ({ filters, createRoute }: IHeaderActions) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState<string>(filters.title);
  const css = useCSS('static-pages-list');

  const onNewStaticPageClick = () => navigate(createRoute);

  const handleSearchEnterEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateFilters({ title: query }));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleQueryClear = () => {
    setQuery('');
    dispatch(updateFilters({ title: '' }));
  };

  return (
    <div className={css('actions')}>
      <Search
        query={query}
        handleQueryClear={handleQueryClear}
        handleSearchChange={handleSearchChange}
        handleSearchEnterEvent={handleSearchEnterEvent}
        placeholder="Buscar por nombre"
      />
      <Button iconWeight="bold" size="sm" onClick={onNewStaticPageClick} hasIcon="left" iconName="plus">
        Nueva página
      </Button>
    </div>
  );
};
