import { useFormContext } from 'react-hook-form';
import { FormGroup, Radio } from '@insuma/mpp-ui/components/form';
import { HelperText } from '@insuma/mpp-ui/components/helper-text';
import type { ICustomVisitDelivery } from 'core/model/interfaces/distributors';
import { DistributorSection } from 'screens/logistics/distributors/components/distributor-section';

interface IDistributorMirrorDaysProps {
  customVisitDeliveries: Array<ICustomVisitDelivery>;
}

export const DistributorMirrorDays = ({ customVisitDeliveries }: IDistributorMirrorDaysProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <DistributorSection
      subtitle="2. Días espejo en fechas de entrega"
      description="Selecciona una opción de día espejo para una fecha de entrega."
    >
      <FormGroup>
        {customVisitDeliveries.map(visitDelivery => (
          <Radio
            defaultChecked={visitDelivery.selected}
            key={visitDelivery.customerVisitDeliveryId}
            id={visitDelivery.customerVisitDeliveryId.toString()}
            value={visitDelivery.customerVisitDeliveryId.toString()}
            {...register('visitDelivery')}
          >
            {visitDelivery.description}
          </Radio>
        ))}
        {errors.visitDelivery && <HelperText isInvalid>{errors.visitDelivery.message}</HelperText>}
      </FormGroup>
    </DistributorSection>
  );
};
