import { Button } from '@insuma/mpp-ui/components/button';
import { Heading, Text } from '@insuma/mpp-ui/components/typography';
import { Modal } from 'shared/components/modal';

import './confirmation-modal.scss';

export interface IConfirmationModalProps {
  head: string;
  message: string | JSX.Element;
  onConfirm?(): void;
  onCancel(): void;
}

export const ConfirmationModal = ({ head, message, onConfirm, onCancel }: IConfirmationModalProps) => (
  <Modal>
    <div className="confirmation-modal">
      <div className="confirmation-modal__content">
        <Heading size="lg" mb="0.5rem" isResponsive>
          {head}
        </Heading>
        <Text size="sm" mb="1rem" isResponsive>
          {message}
        </Text>
      </div>
      <div className="confirmation-modal__actions">
        {onConfirm && (
          <Button variant="primary" onClick={onConfirm}>
            Confirmar
          </Button>
        )}
        <Button variant="tertiary" onClick={onCancel}>
          Volver
        </Button>
      </div>
    </div>
  </Modal>
);
