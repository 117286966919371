import type { IHighlightedCarouselFormData } from 'core/model/interfaces/themes';

export const defaultValues: IHighlightedCarouselFormData = {
  name: '',
  conditions: {
    externalDistributors: [],
    externalPriceGroups: [],
    externalPriceLists: [],
    externalZones: [],
  },
  active: true,
  skus: '',
};
