import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { IHighlightedCarouselFormData } from 'core/model/interfaces/themes';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useHighlightedCarouselDetail = (id: string) => {
  const [formData, setFormData] = useState<IHighlightedCarouselFormData>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    apiService.highlightedCarousel
      .getDetail(id)
      .then(remoteData => {
        setFormData(remoteData);
      })
      .catch(error => {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo el detalle del carrusel de productos'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  return {
    formData,
    isLoading,
    error,
  };
};
