import { useFormContext } from 'react-hook-form';
import { Switch } from '@insuma/mpp-ui/components/form';
import type { IDistributorForm } from 'core/model/interfaces/distributors';
import { DistributorSection } from 'screens/logistics/distributors/components/distributor-section';

export const DistributorWspPurchaseBanner = () => {
  const { register, watch } = useFormContext<IDistributorForm>();
  const isWhatsappButtonEnabled = watch('isWhatsappButtonEnabled');

  return (
    <DistributorSection subtitle="4. Envío de pedido por WhatsApp">
      <Switch {...register('isWhatsappButtonEnabled')} id="isWhatsappButtonEnabled">
        {isWhatsappButtonEnabled
          ? 'Apagar botón de: envío de pedido por WhatsApp'
          : 'Encender botón de: envío de pedido por WhatsApp'}
      </Switch>
    </DistributorSection>
  );
};
