import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { highlightedCarouselRoutePath, themesRoutePath } from 'core/constants/routes.constants';
import type { IHighlightedCarouselFormData } from 'core/model/interfaces/themes';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';
import { HighlightedCarouselForm } from '../components/highlighted-carousel-form';

export const CreateHighlightedCarousel = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (data: IHighlightedCarouselFormData) => {
    try {
      setIsSaving(true);
      await apiService.highlightedCarousel.create(data);

      notification.success('Carrusel de productos creado exitosamente');
      setIsSaving(false);
      navigate(`${themesRoutePath}${highlightedCarouselRoutePath}`);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error));
    }
  };

  return <HighlightedCarouselForm onSubmit={onSubmit} isSaving={isSaving} />;
};
