import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { highlightedCarouselRoutePath, themesRoutePath } from 'core/constants/routes.constants';
import type { IHighlightedCarouselFormData } from 'core/model/interfaces/themes';
import { apiService } from 'core/services';
import { Loading } from 'shared/components/loading';
import { getErrorFromService } from 'shared/utils/https';
import { HighlightedCarouselForm } from '../components/highlighted-carousel-form';
import { useHighlightedCarouselDetail } from '../hooks/use-highlighted-carousel-detail.hooks';

export const EditHighlightedCarousel = () => {
  const { id = '' } = useParams<string>();

  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const highlightedCarouselListRoute = `${themesRoutePath}${highlightedCarouselRoutePath}/`;

  const { formData, isLoading, error } = useHighlightedCarouselDetail(id);

  const onSubmit = async (data: IHighlightedCarouselFormData) => {
    try {
      setIsSaving(true);
      await apiService.highlightedCarousel.edit(data, id);

      notification.success('Carrusel de productos editado exitosamente');
      setIsSaving(false);
      navigate(`${highlightedCarouselListRoute}`);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error));
    }
  };

  useEffect(() => {
    if (error) navigate(highlightedCarouselListRoute);
  }, [error, highlightedCarouselListRoute, navigate]);

  if (!id) return <Navigate to={highlightedCarouselListRoute} />;
  if (isLoading) return <Loading />;
  return <HighlightedCarouselForm onSubmit={onSubmit} formData={formData} isSaving={isSaving} />;
};
