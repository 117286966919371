import { Text } from '@insuma/mpp-ui/components/typography';

import './side-nav-header.scss';

interface ISideNavHeaderProps {
  userFullName: string;
}

export const SideNavHeader = ({ userFullName }: ISideNavHeaderProps) => (
  <div className="sidenav-header">
    <Text as="span" size="sm" isResponsive>
      Bienvenido
    </Text>
    <Text size="lg" weight="semibold" lineHeight="fair" isResponsive mb="0">
      {userFullName}
    </Text>
  </div>
);
