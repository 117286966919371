import { useState } from 'react';
import { Button } from '@insuma/mpp-ui/components/button';
import { Table } from '@insuma/mpp-ui/components/table';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { ExportModal } from 'shared/components/export-modal';
import { Search } from 'shared/components/search';
import { useProductList } from '../hooks/use-product-list.hooks';
import { getProductsColumns } from '../products-utils';

import './product-list.scss';

export const ProductsList = () => {
  const {
    products,
    handleSearchChange,
    handleQueryClear,
    handleSearchEnterEvent,
    handleChangePage,
    query,
    isLoading,
    totalPages,
    currentPage,
    handleViewClick,
    handleExportAgree,
  } = useProductList();
  const css = useCSS('products-list');
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const columns = getProductsColumns(handleViewClick);

  return (
    <>
      <ExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        onExport={handleExportAgree}
        hasDateRange={false}
      />

      <div>
        <h2 className={css('title')}>Gestión de Productos</h2>
        <div className={css('actions')}>
          <Search
            query={query}
            handleQueryClear={handleQueryClear}
            handleSearchChange={handleSearchChange}
            handleSearchEnterEvent={handleSearchEnterEvent}
            placeholder="Buscar por sku o nombre"
          />
          <Button
            size="sm"
            variant="tertiary"
            hasIcon="left"
            iconName="download"
            onClick={() => setIsExportModalOpen(true)}
          >
            Exportación masiva
          </Button>
        </div>

        <Table
          rows={products}
          isLoading={isLoading}
          columns={columns}
          noDataMessage="No se encontraron productos."
          paginationModel={{
            currentPage: currentPage - 1,
            onChangePage: handleChangePage,
            totalPages,
            pageSize: itemsPerPage,
          }}
        />
      </div>
    </>
  );
};
