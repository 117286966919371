import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button } from '@insuma/mpp-ui/components/button';
import { FormGroup, Label, Radio } from '@insuma/mpp-ui/components/form';
import { HelperText } from '@insuma/mpp-ui/components/helper-text';
import { usePriceList, usePricesGroup, useZones } from 'core/hooks/master-entities';
import { EApplyToOption } from 'core/model/enums/distributor.enum';
import type { IDistributorForm } from 'core/model/interfaces/distributors';
import { useSalesForce } from 'screens/logistics/distributors/hooks/use-sales-force.hooks';
import { AutocompleteForm, InputValueForm } from 'shared/components/forms';
import { TextareaForm } from 'shared/components/forms/textarea-form';
import { getPriceGroupList } from './minimum-purchase-form.utils';

import './minimum-purchase-form.scss';

interface IPurchaseFormProps {
  onSaveClick: (index: number) => void;
  onCancelClick: (index: number) => void;
  index: number;
}

export const MinimumPurchaseForm = ({ onSaveClick, onCancelClick, index }: IPurchaseFormProps) => {
  const { branchCode, distributorCode } = useParams();
  const {
    register,
    trigger,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<IDistributorForm>();

  const selectedPriceGroupWatch = watch(`minimumPurchases.${index}.priceGroup`);
  const minimumPurchase = useWatch<IDistributorForm, 'minimumPurchases'>({
    control,
    name: 'minimumPurchases',
  })[index];

  const selectedPriceGroup = selectedPriceGroupWatch;
  const selectedSalesForces = minimumPurchase.externalSalesForce;
  const { pricesGroupList, loading: loadingPriceGroup } = usePricesGroup();
  const { externalZoneList, loading: loadingZones } = useZones();
  const selectedPriceGroupInPriceList = useMemo(
    () => selectedPriceGroup.map(selectedPriceGroupItem => selectedPriceGroupItem.priceGroupCode),
    [selectedPriceGroup],
  );
  const { priceList, loading: loadingPriceList } = usePriceList(selectedPriceGroupInPriceList);
  const { salesForceList, loading: loadingSalesForce } = useSalesForce(branchCode, distributorCode);

  const onSaveChanges = async () => {
    const hasentSquemaErrors = await trigger(`minimumPurchases.${index}`);

    if (hasentSquemaErrors) {
      onSaveClick(index);
    }
  };

  const priceGroupsToUse = getPriceGroupList(salesForceList, pricesGroupList, selectedSalesForces);

  return (
    <div className="minimum-purchase-form">
      <FormGroup>
        <Label>Por fuerza de venta</Label>
        <AutocompleteForm
          source={salesForceList}
          name={`minimumPurchases.${index}.externalSalesForce`}
          isMulti={false}
          isLoading={loadingSalesForce}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.id}
          onChangeProp={() => {
            setValue(`minimumPurchases.${index}.priceGroup`, []);
            setValue(`minimumPurchases.${index}.customerPriceList`, []);
          }}
          withoutFormControl
        />
      </FormGroup>
      <div className="minimum-purchase-form__row">
        <FormGroup className="minimum-purchase-form__group">
          <Label>Por grupo de precios</Label>
          <AutocompleteForm
            isObjectValue
            source={priceGroupsToUse}
            name={`minimumPurchases.${index}.priceGroup`}
            isLoading={loadingPriceGroup}
            isMulti
            getOptionValue={option => option.priceGroupCode}
            getOptionLabel={option => option.priceGroupDescription}
            onChangeProp={() => {
              setValue(`minimumPurchases.${index}.customerPriceList`, []);
            }}
            withoutFormControl
          />
        </FormGroup>
        <FormGroup className="minimum-purchase-form__group">
          <Label>Por lista de precios</Label>
          <AutocompleteForm
            isObjectValue
            source={priceList}
            isMulti
            name={`minimumPurchases.${index}.customerPriceList`}
            isLoading={loadingPriceList}
            isDisabled={selectedPriceGroup.length === 0}
            getOptionValue={option => option.priceListCode}
            getOptionLabel={option => option.priceListDescription}
            withoutFormControl
          />
        </FormGroup>
      </div>
      <FormGroup>
        <Label>Por oficina</Label>
        <AutocompleteForm
          isObjectValue
          source={externalZoneList}
          name={`minimumPurchases.${index}.zones`}
          isLoading={loadingZones}
          getOptionValue={option => option.id}
          getOptionLabel={option => `${option.id} - ${option.description}`}
          isMulti
          withoutFormControl
        />
      </FormGroup>
      <TextareaForm
        label="Por cliente"
        {...register(`minimumPurchases.${index}.customers`)}
        isInvalid={!!errors.minimumPurchases?.[index]?.customers}
        className="minimum-purchase-form__textarea"
        helperText={errors.minimumPurchases?.[index]?.customers?.message}
      />

      <FormGroup>
        {errors.minimumPurchases?.[index] && (
          <HelperText isInvalid>{errors.minimumPurchases[index].message}</HelperText>
        )}
      </FormGroup>
      <div className="minimum-purchase-form__row">
        <FormGroup className="minimum-purchase-form__group">
          <Radio
            value={EApplyToOption.CART}
            id={EApplyToOption.CART}
            {...register(`minimumPurchases.${index}.applyTo`)}
          >
            Carrito
          </Radio>
        </FormGroup>
        <FormGroup className="minimum-purchase-form__group">
          <Radio
            value={EApplyToOption.BRIEFCASE}
            id={EApplyToOption.BRIEFCASE}
            {...register(`minimumPurchases.${index}.applyTo`)}
          >
            Portafolio
          </Radio>
        </FormGroup>
      </div>

      {minimumPurchase?.applyTo === EApplyToOption.CART && (
        <InputValueForm
          label="Al Carrito"
          register={register}
          type="number"
          name={`minimumPurchases.${index}.amount`}
          placeholder="S/0"
          isInvalid={!!errors.minimumPurchases?.[index]?.amount}
          helperText={errors.minimumPurchases?.[index]?.amount?.message}
        />
      )}

      {minimumPurchase?.applyTo === EApplyToOption.BRIEFCASE && (
        <div className="minimum-purchase-form__row">
          <InputValueForm
            className="minimum-purchase-form__group"
            type="number"
            register={register}
            label="Al Carrito"
            name={`minimumPurchases.${index}.amountAlicorp`}
            placeholder="S/0"
            isInvalid={!!errors.minimumPurchases?.[index]?.amountAlicorp}
            helperText={errors.minimumPurchases?.[index]?.amountAlicorp?.message}
          />
          <InputValueForm
            className="minimum-purchase-form__group"
            label="Portafolio Aliados"
            register={register}
            type="number"
            name={`minimumPurchases.${index}.amountAllied`}
            placeholder="S/0"
            isInvalid={!!errors.minimumPurchases?.[index]?.amountAllied}
            helperText={errors.minimumPurchases?.[index]?.amountAllied?.message}
          />
        </div>
      )}

      <div className="minimum-purchase-form__actions">
        <Button size="lg" variant="secondary" onClick={onSaveChanges} type="button">
          Aplicar
        </Button>
        <Button size="lg" variant="tertiary" onClick={() => onCancelClick(index)} type="button">
          Cancelar
        </Button>
      </div>
    </div>
  );
};
