import { ESuggestedProductView } from 'core/model/enums/suggested-product.enum';
import { ESizeThemeModuleImage } from 'core/model/enums/themes.enum';
import type { IBannerForm, IBannerListForm } from 'core/model/interfaces/banners';
import type { ICustomFile } from 'core/model/interfaces/custom-file';
import type {
  ICustomizationModule,
  ICustomizationSuggestedProductFormData,
  IShortcutItemFormData,
} from 'core/model/interfaces/themes';
import { createDate, formatDate } from 'shared/utils/date.utils';
import type {
  IGetThemeModuleDetailDTO,
  IModuleDetailDTO,
  IModuleDTO,
  ISuggestedProductConfigurationDTO,
  IThemeSuggestedProductConfigDTO,
  IUpdateBannerDTO,
  IUpdateShorcutDTO,
} from './theme-modules.dto';
import { extractImageUrl } from './theme-modules.utils';

export const moduleDTOToModuleDisplay = (modules: Array<IModuleDTO>): Array<ICustomizationModule> =>
  modules.map(({ themeModuleType, ...rest }) => ({
    customizationModuleType: themeModuleType.id,
    ...rest,
  }));

export const bannerDTOToShortcutFormData = (banner: IModuleDetailDTO): IShortcutItemFormData => {
  const { id, title, urlRedirect, buttonText, images } = banner;

  return {
    id,
    title,
    link: urlRedirect || '',
    buttonName: buttonText || '',
    image: {
      url: images[0]?.url,
    },
  };
};

export const shortcutFormDataToUpdateModuleDetailDTO = (shortcutFormData: IShortcutItemFormData): IUpdateShorcutDTO => {
  const { title, link, buttonName, id, image } = shortcutFormData;

  return {
    id,
    title,
    urlRedirect: btoa(link),
    buttonText: buttonName,
    images: [{ url: extractImageUrl(image) }],
  };
};

export const bannerListDtoToBannerListForm = (moduleResponse: IGetThemeModuleDetailDTO): IBannerListForm => {
  const bannerListForm: Array<IBannerForm> = moduleResponse.banners.map(banner => ({
    id: banner.id,
    name: banner.title,
    desktopImage: {
      url: banner.images.find(img => img.size === ESizeThemeModuleImage.DESKTOP)?.url || '',
    },
    mobileImage: {
      url: banner.images.find(img => img.size === ESizeThemeModuleImage.MOBILE)?.url || '',
    },
    dateRange: [createDate(banner.validFrom), createDate(banner.validUntil)],
    viewOrder: banner.weight || 0,
    link: banner.urlRedirect,
    isEditing: false,
    isNew: false,
  }));

  return { banners: bannerListForm };
};
export const bannerListFormToBannerListRequest = (
  bannerList: IBannerListForm,
  uploadedImages: Array<ICustomFile>,
): Array<IUpdateBannerDTO> => {
  const bannerListForm = bannerList.banners;

  // TODO: make a refactor for setting uploadedImages in images
  const bannerListRequest: Array<IUpdateBannerDTO> = bannerListForm.map((bannerForm, index) => {
    if (!bannerForm.dateRange || !bannerForm.viewOrder)
      throw new Error('Date range, viewOrder should not be undefined or null');
    return {
      id: bannerForm.id,
      title: bannerForm.name,
      urlRedirect: bannerForm.link ? btoa(bannerForm.link) : undefined,
      validFrom: formatDate(bannerForm.dateRange[0]),
      validUntil: formatDate(bannerForm.dateRange[1]),
      weight: bannerForm.viewOrder,

      // In the case of banners, two images are required for each item (desktop and mobile),
      // so in each iteration two consecutive uploadImages are taken with index*2 and index*2+1
      images: [
        {
          url: extractImageUrl(uploadedImages[index * 2]),
          size: ESizeThemeModuleImage.DESKTOP,
        },
        {
          url: extractImageUrl(uploadedImages[index * 2 + 1]),
          size: ESizeThemeModuleImage.MOBILE,
        },
      ],
    };
  });

  return bannerListRequest;
};

export const configSuggestedProductDTOToSuggestedProductFormData = (
  config: IThemeSuggestedProductConfigDTO,
): ICustomizationSuggestedProductFormData => ({
  ...config,
});

export const suggestedProductFormDataToSuggestedProductConfigurationDTO = (
  suggestedProductFormData: ICustomizationSuggestedProductFormData,
): ISuggestedProductConfigurationDTO => ({
  viewId: ESuggestedProductView.HOMEPAGE,
  config: [
    {
      ...suggestedProductFormData,
    },
  ],
});
