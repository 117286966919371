import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormGroup, Radio } from '@insuma/mpp-ui/components/form';
import { HelperText } from '@insuma/mpp-ui/components/helper-text';
import { EDeliveryTimeOption } from 'core/model/enums/grouping-rules';

import './grouping-rules-delivery-time.scss';

const GroupingRulesDeliveryTime = () => {
  const {
    formState: { errors },
    register,
    watch,
    clearErrors,
  } = useFormContext();

  const useCustomerDeliveryDates = watch('useCustomerDeliveryDates');

  useEffect(() => {
    if (useCustomerDeliveryDates === EDeliveryTimeOption.ASSOCIATED_DATES) clearErrors('deliveryDate');
  }, [clearErrors, useCustomerDeliveryDates]);

  return (
    <div className="row-container">
      <h4 className="row-container__subtitle">3. Tiempo de entrega</h4>
      <div className="delivery-time">
        <FormGroup direction="horizontal" className="delivery-time__options">
          <Radio
            id="delivery-time_useCustomerDeliveryDates"
            value={EDeliveryTimeOption.SPECIFIC_DAYS}
            {...register('useCustomerDeliveryDates')}
          >
            Priorizar días específicos de entrega del cliente
          </Radio>

          <Radio
            id="delivery-time_not-useCustomerDeliveryDates"
            value={EDeliveryTimeOption.ASSOCIATED_DATES}
            {...register('useCustomerDeliveryDates')}
          >
            Priorizar fechas asociadas a esta agrupación
          </Radio>
        </FormGroup>
        <FormGroup>{errors.deliveryTime && <HelperText isInvalid>{errors.deliveryTime.message}</HelperText>}</FormGroup>
      </div>
    </div>
  );
};

export default GroupingRulesDeliveryTime;
