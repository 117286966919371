import { useState } from 'react';
import { Button } from '@insuma/mpp-ui/components/button';
import { Table } from '@insuma/mpp-ui/components/table';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { ExportModal } from 'shared/components/export-modal';
import { Search } from 'shared/components/search';
import { useOrdersList } from '../hooks/use-orders-list.hooks';
import { useOrdersQuery } from '../hooks/use-orders-query.hooks';
import { getOrdersColumns } from '../orders.utils';

import './orders-list.scss';

export const OrdersList = () => {
  const { orders, handleChangePage, isLoading, totalPages, currentPage, handleViewClick, handleExportAgree } =
    useOrdersList();
  const { handleSearchChange, handleQueryClear, handleSearchEnterEvent, query } = useOrdersQuery();
  const css = useCSS('orders-list');

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const columns = getOrdersColumns(handleViewClick);

  return (
    <>
      <ExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        onExport={handleExportAgree}
        hasDateRange
      />

      <div>
        <h2 className={css('title')}>Gestión de Pedidos</h2>
        <div className={css('actions')}>
          <Search
            query={query}
            handleQueryClear={handleQueryClear}
            handleSearchChange={handleSearchChange}
            handleSearchEnterEvent={handleSearchEnterEvent}
            placeholder="Buscar por código o nombre"
          />
          <Button
            size="sm"
            variant="tertiary"
            hasIcon="left"
            iconName="download"
            onClick={() => setIsExportModalOpen(true)}
          >
            Exportación masiva
          </Button>
        </div>

        <Table
          rows={orders}
          isLoading={isLoading}
          columns={columns}
          noDataMessage="No se encontraron resultados de pedidos."
          paginationModel={{
            currentPage: currentPage - 1,
            onChangePage: handleChangePage,
            totalPages,
            pageSize: itemsPerPage,
          }}
        />
      </div>
    </>
  );
};
