import * as yup from 'yup';
import {
  NO_SPECIAL_CHARACTERS_ERROR,
  TEXT_ANY_FIELD_REQUIRED,
  TEXT_FIELD_REQUIRED_ERROR,
  TEXT_INCORRECT_FORMAT,
  textFieldMaxError,
} from 'core/constants/error-messages.constants';
import { NO_SPECIAL_CHARACTER_REGEX, NUMBER_REGEX } from 'core/constants/regex.constant';

export const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(TEXT_FIELD_REQUIRED_ERROR)
    .max(33, textFieldMaxError(33))
    .matches(NO_SPECIAL_CHARACTER_REGEX, NO_SPECIAL_CHARACTERS_ERROR),
  skus: yup
    .string()
    .required(TEXT_FIELD_REQUIRED_ERROR)
    .trim()
    .test('validSkus', TEXT_INCORRECT_FORMAT, text => {
      if (!text) return true;
      const lines = text.split('\n');
      const isValidLines = lines.every(line => NUMBER_REGEX.test(line));
      const hasInvalidTrailingLine = lines[lines.length - 1] === '';
      return isValidLines && !hasInvalidTrailingLine;
    }),
  conditions: yup
    .object()
    .shape({
      externalPriceGroups: yup.array(),
      externalPriceLists: yup.array(),
      externalDistributors: yup.array(),
      externalZones: yup.array(),
    })
    .atLeastOneOf(
      ['externalPriceGroups', 'externalPriceLists', 'externalDistributors', 'externalZones'],
      TEXT_ANY_FIELD_REQUIRED,
    ),
});
