import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@insuma/mpp-ui/components/button';
import { Switch } from '@insuma/mpp-ui/components/form';
import { Img } from '@insuma/mpp-ui/components/img';
import { notification } from '@insuma/mpp-ui/notifications';
import { ImageRoutes } from 'core/constants/image-routes.constants';
import { customizationRoutePath, themesRoutePath } from 'core/constants/routes.constants';
import { EThemeModuleType } from 'core/model/enums/themes.enum';
import { apiService } from 'core/services';
import { handleErrorMessage } from 'shared/utils/errors.utils';
import { getPathToModuleList } from '../../../customization-module.utils';

import './module-item.scss';

interface IModuleItemProps {
  id: number;
  customizationId: number;
  customizationModuleType: EThemeModuleType;
  title: string;
  active: boolean;
  images: Array<string>;
}

export const ModuleItem = ({
  id,
  customizationId,
  customizationModuleType,
  title,
  active,
  images,
}: IModuleItemProps) => {
  const [isActive, setIsActive] = useState(active);
  const navigate = useNavigate();
  const moduleDetailListPath = `${themesRoutePath}${customizationRoutePath}/${customizationId}/modulos/${id}/${getPathToModuleList(
    customizationModuleType,
  )}`;

  const onChangeState = async (newState: boolean) => {
    try {
      await apiService.themeModules.changeStatus(customizationId, id, newState);
      setIsActive(newState);
      notification('Se actualizó el estado correctamente');
    } catch (error) {
      notification.error(handleErrorMessage(error));
    }
  };

  return (
    <div className="row-container">
      <div className="module-item">
        <h2 className="module-item__title">{title}</h2>

        <div className="module-item__actions">
          <Switch
            withoutFormControl
            id={`module-item-${id}`}
            onChange={e => onChangeState(e.target.checked)}
            checked={isActive}
          >
            Visibilidad del módulo
          </Switch>

          <Button size="sm" type="button" onClick={() => navigate(moduleDetailListPath)} width="9.68rem">
            Editar
          </Button>
        </div>

        <div className="module-item__images">
          {images.map((imgUrl, indx) => (
            <picture key={`module-img-${title}-${indx}`}>
              <Img className={`module-item__img--${!isActive ? 'disabled' : ''}`} src={imgUrl} alt={title} />
              <Img className="module-item__reference-banner" src={ImageRoutes.REFERENCE} alt={title} />
            </picture>
          ))}
        </div>
      </div>
    </div>
  );
};
