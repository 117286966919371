import { Button } from '@insuma/mpp-ui/components/button';
import { Form } from '@insuma/mpp-ui/components/form';
import { SearchBox } from '@insuma/mpp-ui/components/search-box';
import { useCSS } from '@insuma/mpp-ui/hooks';

import './search.scss';

interface ISearchProps {
  query: string;
  placeholder?: string;
  size?: 'sm' | 'lg' | 'md';
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleQueryClear: () => void;
  handleSearchEnterEvent: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const Search = ({
  query,
  placeholder = 'Buscar',
  size = 'sm',
  handleSearchChange,
  handleQueryClear,
  handleSearchEnterEvent,
}: ISearchProps) => {
  const css = useCSS('search');

  return (
    <Form onSubmit={handleSearchEnterEvent} className={css()}>
      <SearchBox
        hasPrefix={false}
        width="22.5rem"
        size={size}
        placeholder={placeholder}
        value={query}
        onChange={handleSearchChange}
        onDeleteContent={handleQueryClear}
      />
      <Button isIconOnly iconWeight="bold" iconName="magnifying-glass" size={size} type="submit" />
    </Form>
  );
};
