import { useFormContext } from 'react-hook-form';
import { FormGroup, Radio } from '@insuma/mpp-ui/components/form';
import { ESuggestion, ESuggestionLabel } from 'core/model/enums/suggested-product.enum';

import './suggestion-types.scss';

interface ISuggestionTypesProps {
  name: string;
}

const suggestionTypes = [
  { type: ESuggestion.UNFORGIVABLES, label: ESuggestionLabel.UNFORGIVABLES },
  { type: ESuggestion.ENHANCERS, label: ESuggestionLabel.ENHANCERS },
  { type: ESuggestion.IDEAL_SELECTION, label: ESuggestionLabel.IDEAL_SELECTION },
];

export const SuggestionTypes = ({ name }: ISuggestionTypesProps) => {
  const { register } = useFormContext();
  return (
    <div className="suggestion-types">
      <FormGroup>
        {suggestionTypes.map(({ label, type }) => (
          <Radio key={type} id={`${name}-type-${type}`} value={type} {...register(`${name}.type`)}>
            {label}
          </Radio>
        ))}
      </FormGroup>
    </div>
  );
};
