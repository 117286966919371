import { Table } from '@insuma/mpp-ui/components/table';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { Search } from 'shared/components/search';
import { useCustomersList } from '../hooks/use-customers-list.hooks';
import { getColumns } from './customers.constants';

import './customers-list.scss';

export const CustomersList = () => {
  const {
    customers,
    handleQueryClear,
    handleSearchChange,
    handleSearchEnterEvent,
    handleChangePage,
    handleClick,
    query,
    currentPage,
    isLoading,
    totalPages,
  } = useCustomersList();
  const css = useCSS('customers-view');

  const columns = getColumns({
    handleClick,
  });

  return (
    <div className={css()}>
      <h2 className={css('title')}>Gestión de Clientes</h2>
      <div className={css('actions')}>
        <Search
          query={query}
          handleQueryClear={handleQueryClear}
          handleSearchChange={handleSearchChange}
          handleSearchEnterEvent={handleSearchEnterEvent}
          placeholder="Buscar por RUC, DNI o Razon social"
        />
      </div>
      <div className={css('table')}>
        <Table
          rows={customers}
          isLoading={isLoading}
          columns={columns}
          noDataMessage="No se encontraron resultados"
          paginationModel={{
            currentPage: currentPage - 1,
            totalPages,
            onChangePage: handleChangePage,
            pageSize: itemsPerPage,
          }}
        />
      </div>
    </div>
  );
};
