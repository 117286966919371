import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { Form, FormControl, FormGroup, Label, Switch } from '@insuma/mpp-ui/components/form';
import { HelperText } from '@insuma/mpp-ui/components/helper-text';
import { highlightedCarouselRoutePath, themesRoutePath } from 'core/constants/routes.constants';
import { useDistributorsOptions, usePriceList, usePricesGroup, useZones } from 'core/hooks/master-entities';
import type { IHighlightedCarouselFormData } from 'core/model/interfaces/themes';
import { AutocompleteForm, InputValueForm } from 'shared/components/forms';
import { TextareaForm } from 'shared/components/forms/textarea-form';
import { Link } from 'shared/components/link';
import { defaultValues } from './highlighted-carousel.constants';
import { schema } from './highlighted-carousel-form.schema';

import './highlighted-carousel-form.scss';

interface IHighlightedCarouselFormProps {
  onSubmit(data: IHighlightedCarouselFormData): void;
  formData?: IHighlightedCarouselFormData;
  isSaving?: boolean;
}

export const HighlightedCarouselForm = ({ isSaving = false, formData, onSubmit }: IHighlightedCarouselFormProps) => {
  const formMethods = useForm<IHighlightedCarouselFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    register,
    trigger,
    setValue,
    reset,
    formState: { errors, isValid },
  } = formMethods;

  const { pricesGroupList, loading: loadingPriceGroup } = usePricesGroup();
  const { priceList, loading: loadingPriceList } = usePriceList();
  const { distributorsList, loading: loadingDistributors } = useDistributorsOptions();
  const { externalZoneList, loading: loadingZones } = useZones();

  useEffect(() => {
    if (formData) reset(formData);
  }, [formData, reset]);

  return (
    <div className="highlighted-carousel-form">
      <Link icon="caret-left" to={`${themesRoutePath}${highlightedCarouselRoutePath}`}>
        Atrás
      </Link>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="highlighted-carousel-form__title">{formData ? 'Editar' : 'Nuevo'} carrusel</h2>
        <FormProvider {...formMethods}>
          <div className="highlighted-carousel-form__container">
            <div className="row-container">
              <div className="highlighted-carousel-form__space-between">
                <h4 className="highlighted-carousel-form__subtitle">1. Datos del carrusel</h4>
                <Switch withoutFormControl id="active" {...register('active')}>
                  Mostrar carrusel
                </Switch>
              </div>
              <div className="highlighted-carousel-form__section">
                <InputValueForm
                  label="Nombre de carrusel"
                  register={register}
                  name="name"
                  placeholder="Ejemplo: Para apudex"
                  helperText={errors.name?.message}
                  isInvalid={!!errors.name}
                />
              </div>
            </div>

            <div className="row-container">
              <h4 className="highlighted-carousel-form__subtitle">2. Estructura del carrusel</h4>
              <p>Segmento de usuarios que podrán visualizar la página</p>
              <FormGroup>
                <Label>Por grupo de precios DEX</Label>
                <AutocompleteForm
                  source={pricesGroupList}
                  name="conditions.externalPriceGroups"
                  placeholder="Seleccionar"
                  isLoading={loadingPriceGroup}
                  getOptionValue={option => option.priceGroupCode}
                  getOptionLabel={option => option.priceGroupDescription}
                  onChangeProp={() => {
                    setValue('conditions.externalPriceLists', []);
                    trigger('conditions');
                  }}
                  isMulti
                />
              </FormGroup>
              <FormGroup>
                <Label>Por lista de precios DEX</Label>
                <AutocompleteForm
                  source={priceList}
                  name="conditions.externalPriceLists"
                  placeholder="Seleccionar"
                  isLoading={loadingPriceList}
                  getOptionValue={option => option.priceListCode}
                  getOptionLabel={option => option.priceListDescription}
                  onChangeProp={() => trigger('conditions')}
                  isMulti
                />
              </FormGroup>
              <FormGroup>
                <Label>Por operador</Label>
                <AutocompleteForm
                  source={distributorsList}
                  name="conditions.externalDistributors"
                  placeholder="Seleccionar"
                  isLoading={loadingDistributors}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  onChangeProp={() => trigger('conditions')}
                  isMulti
                />
              </FormGroup>
              <FormGroup>
                <Label>Por oficina</Label>
                <AutocompleteForm
                  source={externalZoneList}
                  name="conditions.externalZones"
                  placeholder="Seleccionar"
                  isLoading={loadingZones}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.description}
                  onChangeProp={() => trigger('conditions')}
                  isMulti
                />
              </FormGroup>
              <FormControl>
                {errors.conditions && <HelperText isInvalid>{errors.conditions.message}</HelperText>}
              </FormControl>
            </div>
          </div>
          <div className="row-container">
            <h4 className="customization-form__subtitle">3. SKU de productos</h4>
            <TextareaForm
              label="Ingresa los SKU de los productos que se mostrarán en el carrusel"
              {...register('skus')}
              placeholder="Ingresar SKU"
              isInvalid={!!errors.skus}
              helperText={errors.skus?.message}
            />
          </div>
        </FormProvider>
        <div className="highlighted-carousel-form__actions">
          <Button type="submit" isLoading={isSaving} width="15rem" disabled={!isValid}>
            Guardar
          </Button>
        </div>
      </Form>
    </div>
  );
};
