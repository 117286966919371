import { useState } from 'react';
import { Button } from '@insuma/mpp-ui/components/button';
import { Table } from '@insuma/mpp-ui/components/table';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { IHighlightedCarouselFilters } from 'core/model/interfaces/themes';
import { useAppDispatch, useAppSelector } from 'core/store';
import { EFilterThemeType, selectHighlightedCarousel, updateFilters } from 'core/store/themes';
import { Search } from 'shared/components/search';
import { DeleteConfirmationModal } from '../components/delete-confirmation-modal';
import { getHighlightedCarouselColumns } from '../highlighted-carousel-list.utils';
import { useHighlightedCarouselList } from '../hooks/use-highlighted-carousel-list.hooks';
import { useHighlightedCarouselListActions } from '../hooks/use-highlighted-carousel-list-actions.hooks';

import './highlighted-carousel-list.scss';

export const HighlightedCarouselList = () => {
  const css = useCSS('highlighted-carousel-list');
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector(selectHighlightedCarousel);
  const [query, setQuery] = useState<string>(filters.q);
  const { highlightedCarousels, totalPages, isLoading } = useHighlightedCarouselList(filters);
  const { itemToDelete, ...handlers } = useHighlightedCarouselListActions();
  const { editCarousel, deleteCarousel, createCarousel, setItemToDelete } = handlers;

  const columns = getHighlightedCarouselColumns(editCarousel, item => setItemToDelete(item));

  const changeFilters = (filters: IHighlightedCarouselFilters) => {
    dispatch(
      updateFilters({
        type: EFilterThemeType.HIGHLIGHTED_CAROUSEL,
        filters,
      }),
    );
  };

  const handleSearchEnterEvent: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    changeFilters({
      page: 1,
      q: query,
    });
  };

  const handleQueryClear = () => {
    setQuery('');
    changeFilters({
      page: 1,
      q: '',
    });
  };

  return (
    <div className={css()}>
      <h2 className={css('title')}>Carrusel de productos</h2>
      <div className={css('actions')}>
        <Search
          query={query}
          handleQueryClear={handleQueryClear}
          handleSearchChange={e => setQuery(e.target.value)}
          handleSearchEnterEvent={handleSearchEnterEvent}
          placeholder="Buscar por nombre"
        />
        <Button size="sm" type="button" iconName="plus" hasIcon="left" onClick={createCarousel}>
          Nuevo carrusel
        </Button>
      </div>
      <div>
        <DeleteConfirmationModal
          selectedItem={itemToDelete}
          setSelectedItem={setItemToDelete}
          onDeleteConfirmation={deleteCarousel}
        />
        <Table
          isLoading={isLoading}
          columns={columns}
          rows={highlightedCarousels}
          paginationModel={{
            currentPage: filters.page - 1,
            onChangePage: page =>
              changeFilters({
                page,
                q: filters.q,
              }),
            totalPages,
            pageSize: itemsPerPage,
          }}
          noDataMessage="No se encontraron resultados"
        />
      </div>
    </div>
  );
};
