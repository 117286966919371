import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from '@insuma/mpp-ui/components/button';
import { EApplyToOption } from 'core/model/enums/distributor.enum';
import type { IDistributorForm } from 'core/model/interfaces/distributors';
import { convertGroup } from 'screens/logistics/distributors/distributor.utils';
import MinimumPurchaseItemField from '../minimum-purchase-item-field';

import './minimum-purchase-item.scss';

interface IPurchaseItemProps {
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  index: number;
}

export const MinimumPurchaseItem = ({ index, onEdit, onDelete }: IPurchaseItemProps) => {
  const { control } = useFormContext<IDistributorForm>();

  const fieldPurchaseItem = useWatch<IDistributorForm, 'minimumPurchases'>({
    control,
    name: 'minimumPurchases',
  })[index];

  const fields = useMemo(() => {
    if (!fieldPurchaseItem) return [];

    return [
      { label: 'Fuerza de venta', value: fieldPurchaseItem.externalSalesForce },
      {
        label: 'Grupo de precios',
        value: convertGroup(fieldPurchaseItem.priceGroup.map(priceGroupItem => priceGroupItem.priceGroupDescription)),
      },
      {
        label: 'Lista de precios',
        value: convertGroup(
          fieldPurchaseItem.customerPriceList.map(customerPriceListItem => customerPriceListItem.priceListDescription),
        ),
      },
      { label: 'Por oficina', value: convertGroup(fieldPurchaseItem.zones.map(zonesItem => zonesItem.description)) },
      { label: 'Por cliente', value: convertGroup(fieldPurchaseItem.customers.split(`\n`)) },
      {
        label: 'Todo el carrito',
        value: fieldPurchaseItem.applyTo === EApplyToOption.CART ? `S/ ${fieldPurchaseItem.amount}` : null,
      },
      {
        label: 'Portafolio Alicorp',
        value: fieldPurchaseItem.applyTo !== EApplyToOption.CART ? `S/ ${fieldPurchaseItem.amountAlicorp}` : null,
      },
      {
        label: 'Portafolio Aliados',
        value: fieldPurchaseItem.applyTo !== EApplyToOption.CART ? `S/ ${fieldPurchaseItem.amountAllied}` : null,
      },
    ];
  }, [fieldPurchaseItem]);

  return (
    <div className="minimum-purcharse-item" key={index}>
      <div className="minimum-purcharse-item--header">
        <Button variant="tertiary" type="button" hasIcon="left" iconName="pencil" onClick={() => onEdit(index)}>
          Editar
        </Button>
        <Button variant="tertiary" type="button" hasIcon="left" iconName="trash" onClick={() => onDelete(index)}>
          Eliminar
        </Button>
      </div>
      <div className="minimum-purcharse-item--content">
        {fields.map(
          (field, idx) => field.value && <MinimumPurchaseItemField key={idx} label={field.label} value={field.value} />,
        )}
      </div>
    </div>
  );
};
